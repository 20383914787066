import { insertBlackListAPI, reportPostAPI, blockingUserAPI, getMyIdListAPI } from "./api/member";
import { isEmpty } from "~/utils/utils";

export const useMemberStore = defineStore("member", () => {
  async function insertBlackListActor({ memCd }) {
    try {
      const res = await insertBlackListAPI({ memCd });

      return res;
    } catch (err) {
      console.error(`insertBlackListActor err : `, err);
    }
  }

  async function reportPostActor({ diaryCd, gubun, reason }) {
    try {
      const res = await reportPostAPI({ diaryCd, gubun, reason });

      return res;
    } catch (err) {
      console.error(`reportPostActor err : `, err);
    }
  }

  async function blockingUserActor({ memCd, delYN }) {
    try {
      const { statusText, data: res = {} } = await blockingUserAPI({ memCd, ...(!isEmpty(delYN) && { delYN }) });
      if (statusText !== "OK" || res?.result === undefined) throw Error;
      return res;
    } catch (err) {
      console.error(`blockingUserActor err : `, err);
    }
  }

  async function getMyIdListActor(payload) {
    try {
      const res = await getMyIdListAPI(payload);
      return res;
    } catch (err) {
      console.error(`getMyListActor err : `, err);
    }
  }

  return {
    insertBlackListActor,
    reportPostActor,
    blockingUserActor,
    getMyIdListActor,
  };
});
